import { Html } from "@react-three/drei"
import useStateMachine from "../stores/stateMachine"
import Button from "./button"

const Start = () => {
	const nextState = useStateMachine((state) => state.nextState)

	return (
		<Html
			transform
			position={[-2, 0, 0]}
			rotation={[-Math.PI / 2, 0, 0]}
			occlude
		>
			<div className="flex flex-col space-y-2">
				<h1>SNOW ANGEL DYNAMICS</h1>

				<Button onClick={nextState}>Start</Button>
			</div>
		</Html>
	)
}

export default Start
