import { XMarkIcon } from "@heroicons/react/24/solid"
import { Html, OrbitControls } from "@react-three/drei"
import usePoses from "../stores/poses"
import { useSettingsStore } from "../stores/settings"
import useStateMachine from "../stores/stateMachine"
import Button from "./button"
import Camera from "./camera"
import Grid from "./grid"
import LightPath from "./lightPath"
import PermissionAndCamera from "./permissionAndCamera"
import Start from "./start"
import WebcamPreview from "./webcamPreview"

const Scene = () => {
	const state = useStateMachine((state) => state.state)
	const prevState = useStateMachine((state) => state.prevState)
	const selectedCamera = useSettingsStore((state) => state.selectedCamera)

	const pathVisible = usePoses((state) => state.pathVisible)

	return (
		<>
			<fog attach="fog" args={["white", 30, 50]} />

			<Grid />

			{state < 3 && <Camera />}

			{state < 2 && (
				<>
					<Start />
					<PermissionAndCamera />
				</>
			)}
			{selectedCamera && state > 1 && state < 3 && (
				<>
					<WebcamPreview selectedCamera={selectedCamera} />
					{pathVisible && <LightPath />}
				</>
			)}

			{state >= 3 && (
				<>
					<LightPath />
					<OrbitControls
						enablePan={false}
						target={[5, 0, 0]}
						autoRotate
						autoRotateSpeed={0.5}
					/>

					<Html>
						<Button onClick={prevState} className="flex-1">
							<div className="flex flex-row space-x-1 items-center">
								<XMarkIcon className="w-6 h-6 text-current" />
								<p>Close</p>
							</div>
						</Button>
					</Html>
				</>
			)}

			{/* <Plane
				scale={[100, 100, 100]}
				rotation={[-Math.PI / 2, 0, 0]}
				position={[0, -10, 0]}
			>
				<meshBasicMaterial color={new Color("red")} />
			</Plane> */}
		</>
	)
}

export default Scene
