import {
	ArrowDownIcon,
	ChevronLeftIcon,
	CubeTransparentIcon,
	EyeIcon,
	EyeSlashIcon,
	SparklesIcon,
} from "@heroicons/react/24/solid"
import { useEffect, useRef } from "react"
import usePoses from "../stores/poses"
import useStateMachine from "../stores/stateMachine"
import Button, { ButtonSize } from "./button"

const RecordInterface = () => {
	const posesRef = useRef(usePoses.getState().poses)

	const isRecording = usePoses((state) => state.isRecording)
	const setIsRecording = usePoses((state) => state.setIsRecording)
	const hasData = usePoses((state) => state.poses.length > 0)

	const prevState = useStateMachine((state) => state.prevState)
	const nextState = useStateMachine((state) => state.nextState)

	const skeletonVisible = usePoses((state) => state.skeletonVisible)
	const toggleSkeletonVisible = usePoses(
		(state) => state.toggleSkeletonVisibility
	)
	const pathVisible = usePoses((state) => state.pathVisible)
	const togglePathVisible = usePoses((state) => state.togglePathVisibility)

	useEffect(
		() =>
			usePoses.subscribe((state) => {
				posesRef.current = state.poses
			}),
		[]
	)

	return (
		<div className="flex flex-col">
			<div className="flex flex-row items-center justify-between space-x-10">
				<Button size={ButtonSize.small} onClick={prevState}>
					<ChevronLeftIcon className="w-6 h-6 text-current" />
				</Button>

				<h1>Capture performance</h1>
			</div>

			<div className="flex flex-row items-center space-x-2 flex-1 mt-2">
				<Button className="flex-1" onClick={toggleSkeletonVisible}>
					<div className="flex flex-row items-center space-x-1">
						{skeletonVisible ? (
							<EyeIcon className="w-6 h-6 text-current" />
						) : (
							<EyeSlashIcon className="w-6 h-6 text-current" />
						)}
						<p>Skeleton</p>
					</div>
				</Button>
				<Button className="flex-1" onClick={togglePathVisible}>
					<div className="flex flex-row items-center space-x-1">
						{pathVisible ? (
							<EyeIcon className="w-6 h-6 text-current" />
						) : (
							<EyeSlashIcon className="w-6 h-6 text-current" />
						)}
						<p>Path</p>
					</div>
				</Button>
			</div>

			<div className="flex flex-row flex-1 space-x-2 mt-2">
				<Button
					className="flex-1"
					onClick={() => {
						setIsRecording(!isRecording)
					}}
				>
					<div className="flex flex-row items-center space-x-1">
						<div
							className={`w-3 h-3 rounded-full border-2 border-red-500 ${
								isRecording ? "animate-pulse bg-red-500" : ""
							}`}
						/>
						<p> {isRecording ? "Stop" : hasData ? "Redo" : "Record"}</p>
					</div>
				</Button>

				{hasData && !isRecording && (
					<Button
						className="flex-1"
						onClick={() => {
							const jsonRepresentation = JSON.stringify(posesRef.current)

							// download jsonReprensentation to file
							const element = document.createElement("a")
							const file = new Blob([jsonRepresentation], {
								type: "application/json",
							})
							element.href = URL.createObjectURL(file)
							element.download = "poses.json"
							document.body.appendChild(element) // Required for this to work in FireFox
							element.click()
						}}
					>
						<div className="flex flex-row space-x-1 items-center">
							<ArrowDownIcon className="w-6 h-6 text-current" />
							<p>Download</p>
						</div>
					</Button>
				)}
			</div>

			{hasData && (
				<div className="flex flex-row flex-1 space-x-2 mt-4">
					<Button onClick={nextState} className="flex-1">
						<div className="flex flex-row space-x-1 items-center">
							<CubeTransparentIcon className="w-6 h-6 text-current" />
							<p>Preview</p>
						</div>
					</Button>

					<Button href="/fabrication_data.src" className="flex-1">
						<div className="flex flex-row space-x-1 items-center">
							<SparklesIcon className="w-6 h-6 text-current" />
							<p>Generate!</p>
						</div>
					</Button>
				</div>
			)}
		</div>
	)
}

export default RecordInterface
