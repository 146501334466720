import * as mpPose from "@mediapipe/pose"
import { Html, Line, Plane, Sphere } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import {
	createDetector,
	Keypoint,
	PoseDetector,
	SupportedModels,
} from "@tensorflow-models/pose-detection"
import { getAdjacentPairs } from "@tensorflow-models/pose-detection/dist/util"
import "@tensorflow/tfjs-backend-webgl"
import { useEffect, useRef, useState } from "react"
import { Color, Group, VideoTexture } from "three"
import usePoses from "../stores/poses"
import RecordInterface from "./recordInterface"

const WebcamPreview = ({ selectedCamera }: { selectedCamera: string }) => {
	const video = document.getElementById("webcam")! as HTMLVideoElement

	const videoTexture = new VideoTexture(video)

	const [detector, setDetector] = useState<PoseDetector | undefined>(undefined)

	const addPoses = usePoses((state) => state.addPoses)
	const skeletonVisible = usePoses((state) => state.skeletonVisible)

	const pointGroup = useRef<Group>(null)
	const lineGroup = useRef<Group>(null)

	useEffect(() => {
		const _createDetector = async () => {
			const model = SupportedModels.BlazePose
			const detector = await createDetector(model, {
				runtime: "mediapipe",
				modelType: "full",
				solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
			})

			setDetector(detector)
		}

		_createDetector()
	}, [])

	useEffect(() => {
		const getWebcamVideo = async () => {
			// Ask for webcam access
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: false,
				video: {
					width: 640,
					height: 480,
					facingMode: "user",
					deviceId: selectedCamera,
				},
			})

			video.srcObject = stream
			video.play()

			// Assign the stream to a video texture
		}

		getWebcamVideo()
	}, [video, selectedCamera])

	useFrame(async () => {
		if (detector !== undefined) {
			if (video.readyState < 2) {
				await new Promise((resolve) => {
					video.onloadeddata = () => {
						resolve(video)
					}
				})
			}

			try {
				const poses = await detector.estimatePoses(video, {
					flipHorizontal: false,
				})

				// console.log(poses)

				let keyPoints3d: Keypoint[] | undefined = undefined
				if (poses && poses.length > 0 && poses[0].keypoints3D !== undefined) {
					keyPoints3d = poses[0].keypoints3D
				}
				if (
					pointGroup.current &&
					lineGroup.current &&
					keyPoints3d !== undefined
				) {
					keyPoints3d.forEach((point, index) => {
						pointGroup.current!.children[index].position.set(
							point.x,
							-point.y,
							point.z ?? 0
						)
					})

					addPoses(keyPoints3d)

					getAdjacentPairs(SupportedModels.BlazePose).forEach((pair, index) => {
						const [from, to] = pair
						const fromPoint = keyPoints3d![from]
						const toPoint = keyPoints3d![to]

						// @ts-ignore
						lineGroup.current!.children[index].geometry.setPositions([
							fromPoint.x,
							-fromPoint.y,
							fromPoint.z ?? 0,
							toPoint.x,
							-toPoint.y,
							toPoint.z ?? 0,
						])
					})
				}
			} catch (error) {
				// detector.dispose()
				// detector = null
				// alert(error)
				console.log(error)
			}
		}
	})

	return (
		<>
			<Plane position={[-4, 1.36, 2]} args={[0.8, 0.6]} scale={8}>
				<meshBasicMaterial map={videoTexture} />
			</Plane>

			<Html transform position={[-4, 6.5, 2]}>
				<RecordInterface />
			</Html>

			<group
				position={[5, 0, 0]}
				rotation={[0, Math.PI, 0]}
				scale={skeletonVisible ? 0.000000001 : 10}
			>
				<group ref={pointGroup}>
					{new Array(36).fill(0).map((_, i) => (
						<Sphere key={i} scale={[0.01, 0.01, 0.01]}>
							<meshStandardMaterial color={new Color("red")} />
						</Sphere>
					))}
				</group>

				<group ref={lineGroup}>
					{new Array(40).fill(0).map((_, i) => (
						<Line
							key={i}
							points={[0, 0, 0, 0, 0, 0]}
							color={new Color("red")}
						/>
					))}
				</group>
			</group>
		</>
	)
}

export default WebcamPreview
