import { Html } from "@react-three/drei"
import { useCallback, useEffect, useState } from "react"
import { useSettingsStore } from "../stores/settings"
import useStateMachine from "../stores/stateMachine"
import Button, { ButtonSize } from "./button"

const PermissionAndCamera = () => {
	const [hasPermissions, setHasPermissions] = useState(false)

	const cameras = useSettingsStore((state) => state.cameras)
	const selectedCamera = useSettingsStore((state) => state.selectedCamera)
	const setCamera = useSettingsStore((state) => state.updateCamera)
	const updateDevices = useSettingsStore((state) => state.updateIO)

	const nextState = useStateMachine((state) => state.nextState)

	const checkPermission = useCallback(async () => {
		let hasPermissions = false
		const devices = await navigator.mediaDevices.enumerateDevices()
		devices.forEach((device) => {
			if (device.label && device.label.length > 0) {
				hasPermissions = true
			}
		})

		setHasPermissions(hasPermissions)

		if (hasPermissions) {
			updateDevices(devices)
		}
	}, [updateDevices])

	useEffect(() => {
		checkPermission()
	}, [checkPermission])

	useEffect(() => {
		const onDeviceChange = async () => {
			const devices = await navigator.mediaDevices.enumerateDevices()
			updateDevices(devices)
		}

		navigator.mediaDevices.addEventListener("devicechange", onDeviceChange)

		return () =>
			navigator.mediaDevices.removeEventListener("devicechange", onDeviceChange)
	}, [updateDevices])

	const askForPermission = useCallback(async () => {
		await navigator.mediaDevices.getUserMedia({ audio: false, video: true })

		checkPermission()
	}, [checkPermission])

	return (
		<Html
			transform
			position={[10, 0, 0]}
			rotation={[-Math.PI / 2, 0, 0]}
			occlude
			scale={[1, 1, 1]}
		>
			{hasPermissions ? (
				<div className="flex flex-col ">
					<h1>Select your camera:</h1>

					<div className="flex flex-row space-x-2 mt-2">
						<label className="text-sm">Camera:</label>
						<select
							className="text-sm outline-none transition-all border border-black text-black w-56"
							value={selectedCamera ?? "default"}
							onChange={({ target: { value } }) => {
								setCamera(value)
							}}
						>
							<option disabled value="default">
								Select camera:
							</option>
							{cameras.map((v) => (
								<option value={v.deviceId} key={v.deviceId}>
									{v.label}
								</option>
							))}
						</select>
					</div>

					<Button
						className="mt-4"
						size={ButtonSize.small}
						disabled={selectedCamera === undefined}
						onClick={nextState}
					>
						Continue
					</Button>
				</div>
			) : (
				<div className="flex flex-col items-center space-y-2">
					<h1>To start please allow access to the camera</h1>

					<Button
						onClick={() => {
							askForPermission()
						}}
					>
						Allow
					</Button>
				</div>
			)}
		</Html>
	)
}

export default PermissionAndCamera
