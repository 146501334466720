import { Euler, Vector3 } from "three"
import create from "zustand"

enum State {
	Start = 0,
	Permission = 1,
	Capture = 2,
	Sculpture = 3,
}

const isMobile = window.innerHeight > window.innerWidth

export const cameraPositionForState = (
	state: State
): [position: Vector3, rotation: Euler, zoom: number] => {
	switch (state) {
		case State.Start:
			return [
				new Vector3(-4.8, 4.8, 5),
				new Euler(-Math.PI / 4, -Math.PI / 8, -Math.PI / 8),
				isMobile ? 90 : 200,
			]

		case State.Permission:
			return [
				new Vector3(7, 4.5, 5),
				new Euler(-Math.PI / 4, -Math.PI / 8, -Math.PI / 8),
				isMobile ? 70 : 140,
			]

		case State.Capture:
			return [
				new Vector3(0, 3, 5),
				new Euler(-Math.PI / 16, -Math.PI / 12, -0.02),
				isMobile ? 20 : 60,
			]

		case State.Sculpture:
			return [
				new Vector3(0, 3, 5),
				new Euler(-Math.PI / 16, -Math.PI / 12, -0.02),
				60,
			]

		default:
			break
	}
	return [new Vector3(0, 0, 0), new Euler(0, 0, 0), 16]
}

interface StateMachine {
	state: State
	nextState: () => void
	prevState: () => void
}

const useStateMachine = create<StateMachine>((set, get) => ({
	state: State.Start,
	nextState: () => {
		const currentState = get().state

		set({ state: currentState + 1 })
	},
	prevState: () => {
		const currentState = get().state

		set({ state: currentState - 1 })
	},
}))

export default useStateMachine
