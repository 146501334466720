import type { PropsWithChildren } from "react"
import { forwardRef } from "react"

export enum ButtonTheme {
	default = "border border-black text-black",
	secondary = "bg-white text-gray-800 border",
	tertiary = "bg-gray-200 text-gray-800",
}

export enum ButtonSize {
	big = "px-5 py-3",
	regular = "px-2 py-2",
	small = "px-1 py-1",
}

export type ButtonProps = Omit<
	React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement> &
			React.LinkHTMLAttributes<HTMLAnchorElement> &
			React.AnchorHTMLAttributes<HTMLAnchorElement>,
		HTMLButtonElement
	> & {
		className?: string
		innerClassName?: string
		to?: string
		loading?: boolean
		// alert?: boolean
		theme?: ButtonTheme
		size?: ButtonSize
		flat?: boolean
	},
	"ref"
>

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
	(
		{
			loading = false,
			className,
			children,
			theme = ButtonTheme.default,
			size = ButtonSize.regular,
			innerClassName,
			disabled,
			// alert = false,

			flat = false,
			...props
		},
		ref
	) => {
		let Element: any = "button"
		if (props.href) {
			Element = "a"
		}

		return (
			<Element
				ref={ref}
				{...props}
				className={`text-sm outline-none transition-all flex items-center justify-center select-none relative disabled:opacity-40 uppercase font-medium ${size} ${theme} ${className} ${
					!disabled && "buttonAnimation"
				}`}
				to={props.to ?? ""}
				disabled={disabled}
			>
				<span
					className={`absolute inset-0 overflow-hidden flex items-center justify-center rounded-md ${
						loading ? "opacity-100" : "opacity-0"
					} transition-opacity select-none pointer-events-none`}
				>
					<p>Loading...</p>
				</span>

				{flat ? (
					children
				) : (
					<span
						className={`${
							loading ? "opacity-0" : "opacity-100"
						} transition-opacity flex items-center ${innerClassName}`}
					>
						{children}
					</span>
				)}
				{/* {alert && (
					<div className="bg-red-600 w-2 h-2 rounded-full absolute -top-1 -right-1 animate-ping" />
				)} */}
			</Element>
		)
	}
)

Button.displayName = "Button"

export default Button
