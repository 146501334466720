import create from "zustand"

type SettingsStore = {
	selectedCamera: string | undefined
	cameras: MediaDeviceInfo[]
	updateIO: (devices: MediaDeviceInfo[]) => void
	updateCamera: (id: string) => void
}

export const useSettingsStore = create<SettingsStore>((set, get) => ({
	selectedCamera:
		"f3125951da4a528fa37dbdaac0dc466ec503720a7413fadb841d7e13914119d3",
	cameras: [],

	updateIO: (devices) => {
		const cameras = devices.filter((device) => device.kind === "videoinput")

		set({
			cameras,
		})

		// const { selectedCamera, updateCamera } = get()

		// updateDevice(cameras, selectedCamera, updateCamera)
	},
	updateCamera: (id) => {
		console.log("SETTING CAM")
		set({ selectedCamera: id })
	},
}))
