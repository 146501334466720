import { Canvas } from "@react-three/fiber"
import { Suspense, useEffect } from "react"
import Scene from "./components/scene"
import useRhino from "./stores/rhino"

let didInit = false

function App() {
	const init = useRhino((state) => state.init)

	useEffect(() => {
		if (!didInit) {
			init()

			didInit = true
		}
	}, [init])

	return (
		<div className="w-screen h-screen">
			<Canvas
				orthographic
				dpr={[1, 2]}
				camera={{ position: [-8, 10, 5], zoom: 15, near: -100 }}
			>
				<Suspense fallback={null}>
					<Scene />
				</Suspense>
			</Canvas>

			<video
				id="webcam"
				style={{ display: "none" }}
				autoPlay
				playsInline
			></video>

			{/* <Interface /> */}
		</div>
	)
}

export default App
