import { Line } from "@react-three/drei"
import { useEffect, useRef } from "react"
import { CatmullRomCurve3, Color, Mesh, Vector3 } from "three"
import usePoses from "../stores/poses"

const LightPath = () => {
	const displayCurve = useRef<Mesh>(null)
	const poses = usePoses((state) => state.poses)

	useEffect(() => {
		const points = poses.map((pose) => {
			return pose.map((trackpedPoint) => {
				return new Vector3(
					trackpedPoint.x * 10,
					-trackpedPoint.y * 10,
					(trackpedPoint.z ?? 0) * 10
				)
			})
		})

		if (points.length > 10) {
			const flipped = points[0].map((val, index) =>
				points.map((row) => row[row.length - 1 - index])
			)
			const curvePoints = flipped.map((points) => {
				const curve = new CatmullRomCurve3(
					points.filter(function (_, i) {
						return i % 7 === 0
					}),
					false,
					"catmullrom",
					0.5
				)

				return curve.getPoints(1000)
			})

			if (displayCurve.current) {
				// @ts-ignore
				displayCurve.current.geometry.setPositions(
					curvePoints
						.flatMap((v) => v)
						.map((point) => [point.x, point.y, point.z])
						.flatMap((v) => v)
				)
			}
		}
	}, [poses])

	return (
		<>
			<Line
				// @ts-ignore
				ref={displayCurve}
				points={[0, 0, 0]}
				color={new Color("blue")}
				lineWidth={0.5}
				position={[5, 0, 0]}
				rotation={[0, Math.PI, 0]}
			/>
		</>
	)
}

export default LightPath
