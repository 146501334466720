import { useFrame } from "@react-three/fiber"
import { Euler, MathUtils, Vector3 } from "three"
import useStateMachine, { cameraPositionForState } from "../stores/stateMachine"

export function damp(
	target: Vector3,
	to: Vector3,
	step: number,
	delta: number
) {
	target.x = MathUtils.damp(target.x, to.x, step, delta)
	target.y = MathUtils.damp(target.y, to.y, step, delta)
	target.z = MathUtils.damp(target.z, to.z, step, delta)

	return target
}

function dampEuler(target: Euler, to: Euler, step: number, delta: number) {
	return new Euler(
		MathUtils.damp(target.x, to.x, step, delta),
		MathUtils.damp(target.y, to.y, step, delta),
		MathUtils.damp(target.z, to.z, step, delta)
	)
}

const Camera = () => {
	const currentState = useStateMachine((state) => state.state)

	useFrame((state, delta) => {
		const [position, rotation, zoom] = cameraPositionForState(currentState)
		const step = 1.5

		const newPosition = damp(state.camera.position, position, step, delta)
		const newRotation = dampEuler(state.camera.rotation, rotation, step, delta)

		state.camera.position.set(newPosition.x, newPosition.y, newPosition.z)
		state.camera.setRotationFromEuler(newRotation)
		state.camera.zoom = MathUtils.damp(state.camera.zoom, zoom, step, delta)

		state.camera.updateProjectionMatrix()
	})

	return <></>
}

export default Camera
