import create from "zustand"

interface RhinoStore {
	rhino: any | undefined
	init: () => void
}

const useRhino = create<RhinoStore>((set) => ({
	rhino: undefined,
	init: async () => {
		// @ts-ignore
		const rhino = await globalThis.rhino3dm()

		set({ rhino })
	},
}))

export default useRhino
