import { Keypoint } from "@tensorflow-models/pose-detection"
import create from "zustand"
import { immer } from "zustand/middleware/immer"

interface PoseStore {
	poses: Keypoint[][]
	addPoses: (poses: Keypoint[]) => void

	isRecording: boolean
	setIsRecording: (isRecording: boolean) => void

	skeletonVisible: boolean
	toggleSkeletonVisibility: () => void

	pathVisible: boolean
	togglePathVisibility: () => void
}

const usePoses = create(
	immer<PoseStore>((set) => ({
		poses: [],

		addPoses: (poses: Keypoint[]) => {
			set((state) => {
				if (state.isRecording) {
					state.poses.push(poses)
				}
			})
		},

		isRecording: false,
		setIsRecording: (isRecording: boolean) => {
			set((state) => {
				if (isRecording) {
					state.poses = []
				}
				state.isRecording = isRecording
			})
		},

		skeletonVisible: true,
		toggleSkeletonVisibility: () => {
			set((state) => {
				state.skeletonVisible = !state.skeletonVisible
			})
		},

		pathVisible: true,
		togglePathVisibility: () => {
			set((state) => {
				state.pathVisible = !state.pathVisible
			})
		},
	}))
)

export default usePoses
